<template>
  <dog-dialog
    ref="dogDialog"
    width="800px"
    title="设置角色"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <div class="flex_row dialog_zone_scroll setRolesDialog">
      <dog-scroll-box class="setRolesDialog__checkGroup">
        <el-checkbox-group
          v-model="roleCheckList"
          class="flex_column"
          @change="checkChange"
        >
          <el-checkbox :label="role.id" v-for="role in roleList" :key="role.id">
            {{ role.roleName }}
          </el-checkbox>
        </el-checkbox-group>
      </dog-scroll-box>
      <el-divider direction="vertical"></el-divider>
      <dog-scroll-box class="flex_1">
        <el-tree
          ref="resourceTree"
          :data="resourceData"
          show-checkbox
          node-key="id"
          :default-expand-all="true"
          @node-click="handleNodeClick"
        >
        </el-tree>
      </dog-scroll-box>
      <el-divider direction="vertical"></el-divider>
      <dog-scroll-box class="flex_1">
        <el-tree
          ref="resourceBtnTree"
          :data="resourceBtnData"
          show-checkbox
          node-key="id"
          :default-expand-all="true"
        >
        </el-tree>
      </dog-scroll-box>
    </div>
  </dog-dialog>
</template>

<script>
  import userService from '@/api/service/user.js';
  import roleService from '@/api/service/role';
  import resourceService from '@/api/service/resource';

  export default {
    name: 'setRolesDialog',
    data() {
      return {
        userId: '',
        roleCheckList: [],
        roleList: [],
        resourceData: [],
        resourceBtnData: [],
        currentResourceId: null
      };
    },
    methods: {
      openFrame(id) {
        this.userId = id;
        this.$refs.dogDialog.open();
        userService.getUserRoleIds({ userId: id }).then((res) => {
          this.roleCheckList = res;
          this.getBatchRoleResourceIds();
        });
        roleService.getRoleList().then((res) => {
          this.roleList = res.list;
        });
        resourceService.getResourceTree().then((res) => {
          this.resourceData = this.setTreeDisabled(res.list);
        });
      },
      getBatchRoleResourceIds() {
        roleService
          .getBatchRoleResourceIds({
            roleIds: JSON.stringify(this.roleCheckList)
          })
          .then((res) => {
            this.$refs.resourceTree.setCheckedKeys(res);
          });
      },
      setTreeDisabled(tree) {
        const loopTree = (nodes) => {
          nodes.forEach((item) => {
            item.disabled = true;
            item.label = item.title;
            if (Array.isArray(item.children)) {
              loopTree(item.children);
            }
          });
        };
        loopTree(tree);
        return tree;
      },
      handleNodeClick(data) {
        this.currentResourceId = data.id;
        this.getBatchRoleResourceBtnIdsByResource();
      },
      getBatchRoleResourceBtnIdsByResource() {
        if (!this.currentResourceId) {
          return;
        }
        resourceService
          .getResourceBtnList({ resourceId: this.currentResourceId })
          .then((res) => {
            this.resourceBtnData = [
              {
                label: '全选',
                id: 0,
                disabled: true,
                children: res.list.map((item) => {
                  return {
                    ...item,
                    label: item.name,
                    disabled: true
                  };
                })
              }
            ];
          });
        roleService
          .getBatchRoleResourceBtnIdsByResource({
            roleIds: JSON.stringify(this.roleCheckList),
            resourceId: this.currentResourceId
          })
          .then((res) => {
            this.$refs.resourceBtnTree.setCheckedKeys(res);
          });
      },
      checkChange() {
        this.getBatchRoleResourceIds();
        this.getBatchRoleResourceBtnIdsByResource();
      },
      confirm(done) {
        const params = {
          userId: this.userId,
          roleIds: this.roleCheckList
        };
        userService
          .setUserRoleIds(params)
          .then(() => {
            this.$message.success('保存成功！');
            this.$emit('confirm');
            this.close();
          })
          .finally(() => {
            done();
          });
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.roleCheckList = [];
        this.roleList = [];
        this.resourceData = [];
        this.resourceBtnData = [];
        this.currentResourceId = null;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(setRolesDialog) {
    @include e(checkGroup) {
      width: 200px;
      /deep/ .el-checkbox {
        margin-bottom: 5px;
      }
    }
  }
</style>
